<template>
  <div class="terminal-min-height">
    <portal to="header">
      <terminal-header
          :title="$t('Receive tracking numbers')"
          @barcode="handleBarcode"
      />
    </portal>

    <div v-if="action === 'warehouse'" :style="`height: ${terminalContainerHeight}px;`">

      <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
        <h6 class="q-ma-none text-subtitle2 text-weight-bold">
          {{ $t('Warehouses') }}
        </h6>
      </div>

      <div v-if="items.length === 0 && !$service.warehouse.isLoading" class="q-pa-md q-gutter-sm">
        <q-banner inline-actions rounded class="bg-orange text-white">
          {{ $t('There was no warehouses found') }}
        </q-banner>
      </div>

      <q-table
          :style="`height: ${terminalContainerHeight}px`"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="$service.warehouse.isLoading"
          :filter="filter"
          :rows-per-page-options="[]"
          table-header-class="d-none"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
          >
            <q-td
                key="details"
                :props="props"
            >
              <div class="row items-center q-pt-xs">
                <q-badge
                    :color="stateColors[props.row.state]"
                    :label="$t(props.row.state)"
                    class="q-py-xs"
                />

                <div class="col q-px-sm text-caption text-capitalize">
                  {{ props.row.type }}
                </div>

                <div v-if="props.row.created">
                  {{ $moment(props.row.created.date).format($appOptions.formats.date) }}
                </div>
              </div>

              <div class="text-caption">
                <small v-if="props.row._embedded.country">
                  {{ props.row._embedded.country.name }}
                </small>

                <small v-if="props.row._embedded.currency">
                  {{ props.row._embedded.currency.code }}
                </small>

                <div v-if="props.row._embedded.owner">
                  <small>{{ props.row._embedded.owner.name }}</small>
                </div>
              </div>

              <div class="text-caption">
                {{ props.row.comment }}
              </div>

              <div class="text-subtitle2 text-bold">
                {{ props.row.id }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <div v-else-if="action === 'next'" class="q-pa-none" :style="`height: ${terminalContainerHeight}px;`">
      <assistant-object :data="assistantObject"/>
    </div>


    <div v-if="action === 'final'" class="q-pa-none" :style="`height: ${terminalContainerHeight}px;`">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0951.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <h6 class="q-my-none text-center">
        {{ $t('Well done!') }}
      </h6>

      <div class="row items-center justify-center q-py-md border-bottom">
        <div class="text-center text-subtitle2 q-pt-xs relative">
          <div class="q-mt-xs text-weight-bold">
            <q-btn
                ref="maxBtn"
                :label="scanned"
                color="primary"
                size="28px"
                class="q-btn--rounded-xs"
                style="min-width: 110px;"
                outline
            />
          </div>
        </div>
      </div>

      <div class="q-my-md text-center" v-if="scannedString">
        <strong>{{ scannedString }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TrackingNumbers',
  data () {
    return {
      dir: 'to',
      items: [],
      action: 'warehouse',
      filter: '',
      warehouse: null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      assistantObject: {
        title: 'Please scan',
        caption: 'Tracking Number',
        captionColor: 'amber',
        image: 'scanDistributionPlace'
      },
      stateColors: {
        active: 'success',
        inactive: 'dark'
      },
      scannedString: null,
      scanned: 0
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  computed: {
    ...mapGetters([
      'terminalSettings',
      'terminalContainerHeight'
    ]),
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: [
          { type: 'in', field: 'type', values: ['fulfillment'] }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      return this.$service.warehouse.getAll(query)
          .then(({ page, totalItems, items, totalPages }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            return { page, totalItems, items, totalPages }
          })
    },
    handleBarcode (barcode) {
      const data = {
        warehouse: this.warehouse.id,
        trackingNumber: barcode.value,
        count: 1
      }

      return this.$service.acceptanceItem.save(data, undefined, 'new')
          .then(() => {
            this.action = 'final'

            this.scanned++

            this.scannedString = barcode.value
          })
    },
    onRowDblClick (row) {
      this.warehouse = row
      this.action = 'next'
    }
  }
}
</script>
